import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../../Shared/StyledComponents';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';
import { Grid, TextField } from '@material-ui/core';

type PromotionsSettingsProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    backgroundColor: string;
    color: string;
    borderRadius: string;
    borderStyle: string;
    borderWidth: string;
    borderColor: string;
    height: string;
  }) => void;
  backgroundColor: string;
  color: string;
  borderRadius: string;
  borderStyle: string;
  borderWidth: string;
  borderColor: string;
  height: string;
};

const PromotionsSettingsWindow: FunctionComponent<PromotionsSettingsProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  backgroundColor,
  color,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor,
  height
}) => {
  const { t } = useTranslation();
  const [promotionsColor, setPromotionsColor] = useState<string>(color);
  const [promotionsBackgroundColor, setPromotionsBackgroundColor] = useState<string>(backgroundColor);
  const [promotionsBorderRadius, setPromotionsBorderRadius] = useState<string>(
    borderRadius.includes('px') ? borderRadius.slice(0, -2).trim() : '0'
  );
  const [promotionsBorderColor, setPromotionsBorderColor] = useState<string>(borderColor);
  const [promotionsBorderWidth, setPromotionsBorderWidth] = useState<string>(
    borderWidth.includes('px') ? borderWidth.slice(0, -2).trim() : '0'
  );
  const [promotionsSpacing, setPromotionsSpacing] = useState<string>(
    height.includes('px') ? height.slice(0, -2).trim() : '0'
  );

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.promotionsSettings.header')}
      onCloseClick={onCloseClick}
      maxWidth="md"
      onSubmit={() =>
        onFormSubmit({
          color: promotionsColor,
          backgroundColor: promotionsBackgroundColor,
          borderRadius: promotionsBorderRadius + 'px',
          borderStyle: 'solid',
          borderWidth: promotionsBorderWidth + 'px',
          borderColor: promotionsBorderColor,
          height: promotionsSpacing + 'px'
        })
      }
    >
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.promotionsSettings.general')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.backgroundColor')}:</div>
            <ColorPicker color={promotionsBackgroundColor} onChange={setPromotionsBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.color')}:</div>
            <ColorPicker color={promotionsColor} onChange={setPromotionsColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.spacing')}:</div>
            <TextField
              id="promotions-spacing"
              type="number"
              value={promotionsSpacing}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const spacing = Math.abs(Number(event.target.value)) || '';
                setPromotionsSpacing((spacing as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsSpacing('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.promotionsSettings.border')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderRadius')}:</div>
            <TextField
              id="promotions-border-radius"
              type="number"
              value={promotionsBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setPromotionsBorderRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsBorderRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderWidth')}:</div>
            <TextField
              id="promotions-border-width"
              type="number"
              value={promotionsBorderWidth}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const width = Math.abs(Number(event.target.value)) || '';
                setPromotionsBorderWidth((width as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsBorderWidth('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderColor')}:</div>
            <ColorPicker color={promotionsBorderColor} onChange={setPromotionsBorderColor} />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

export default PromotionsSettingsWindow;
