import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { FullSizePageContainer } from '../../../PageStyledComponents';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../Shared/Layout/PageHeader';
import CodeIcon from '@material-ui/icons/Code';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../../config/app/linksConstants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RouteComponentProps } from 'react-router';
import Toast from '../../../../Shared/Toast/Toast';
import Loader from '../../../../Shared/Loading/Loader';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { ProjectModelV1, ProjectModelV2 } from '../../../Editors/EmailDndEditor/types';
import { closeFullscreen, createNetworkErrorObject, openFullscreen, useTypedSelector } from '../../../../../utils';
import { hasPermission } from '../../../../../utils/permissions';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { preventDragEvent } from '../../../Editors/EmailDndEditor/Utils/Uploaders';
import { Editor } from '../../../Editors/EmailDndEditor/Partials/Editor';
import { sanitizeToV2Model } from '../../../Editors/EmailDndEditor/Utils';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminEditEmailTemplateModelWindow from '../Windows/AdminEditEmailTemplateModelWindow';
import { DamSystemName } from '../../../../../store/SystemSettings/types';
import { myOrganizationOperations } from '../../../../../store/MyOrganization';

type EmailTemplateEditorPageProps = RouteComponentProps<{ templateId: string }> & {};

const EmailTemplateEditorPage: FunctionComponent<EmailTemplateEditorPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [manuallyChangingProjectModel, setManuallyChangingProjectModel] = useState(false);
  const [projectModel, setProjectModel] = useState<ProjectModelV2 | null>(null);
  const [template, setTemplate] = useState<AdminEmailTemplate | null>(null);
  const [damStatus, setDamStatus] = useState<{ damActive: boolean; system: DamSystemName | null }>({
    damActive: false,
    system: null
  });
  const authData = useTypedSelector((state) => state.auth);
  const { role } = authData;

  const [editProjectModelWindowOpen, onEditProjectModelWindowOpen, onEditProjectModelWindowClose] = useOpenHandler();

  const templateId = match.params.templateId;

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const saveProject = useCallback(async () => {
    setSaving(true);
    try {
      if (projectModel) {
        await adminEmailTemplatesOperations.saveModel(templateId, projectModel);
      }
      Toast.success(t('notifications.projectSave.success'));
      setSaving(false);
      return true;
    } catch (e) {
      Toast.error(t('notifications.projectSave.error'));
      setSaving(false);
      throw 'project_save_error';
    }
  }, [projectModel]);

  const toggleFullscreen = () => {
    if (fullscreen) {
      closeFullscreen();
      setFullscreen(false);
    } else {
      openFullscreen();
      setFullscreen(true);
    }
  };

  const rightActionButtons = [
    {
      onClick: toggleFullscreen,
      label: t('common.fullscreen'),
      icon: fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />,
      variant: 'outlined' as const,
      id: 'fullscreen'
    },
    {
      onClick: () => saveProject(),
      label: t('common.save'),
      icon: <SaveIcon />,
      id: 'save',
      disabled: !hasPermission(role, ['templatesUpload']) || saving
    }
  ];

  if (hasPermission(role, ['debugJsonEmailTemplateEditor'])) {
    rightActionButtons.unshift({
      onClick: onEditProjectModelWindowOpen,
      label: 'Edit Model',
      icon: <CodeIcon />,
      variant: 'outlined' as const,
      id: 'edit-json-model'
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectData = await adminEmailTemplatesOperations.show(templateId);
        setTemplate(projectData);

        const response = await adminEmailTemplatesOperations.getTemplateModel(templateId);
        if (response.model.version === 2) {
          setProjectModel((response.model as unknown) as ProjectModelV2);
        } else {
          setProjectModel(sanitizeToV2Model((response.model as unknown) as ProjectModelV1));
        }
      } catch (e) {
        Toast.error(
          t('notifications.newsletterProjectFetch.error', { rawError: `(${createNetworkErrorObject(e).message})` })
        );
      } finally {
        setLoading(false);
      }

      try {
        const damStatusResponse = await myOrganizationOperations.getDamStatus();
        setDamStatus(damStatusResponse);
      } catch (e) {
        console.warn('Error occurred when trying to fetch DAM status', e);
      }
    };
    fetchData();

    document.addEventListener('drop', preventDragEvent);
    document.addEventListener('dragdrop', preventDragEvent);
    document.addEventListener('dragenter', preventDragEvent);
    document.addEventListener('dragleave', preventDragEvent);
    document.addEventListener('dragover', preventDragEvent);

    return () => {
      document.removeEventListener('drop', preventDragEvent);
      document.removeEventListener('dragdrop', preventDragEvent);
      document.removeEventListener('dragenter', preventDragEvent);
      document.removeEventListener('dragleave', preventDragEvent);
      document.removeEventListener('dragover', preventDragEvent);
    };
  }, []);

  return (
    <FullSizePageContainer>
      <PageHeader
        variant="white"
        title={'Template Editor'}
        leftActionButtons={[backButton]}
        rightActionButtons={rightActionButtons}
      />

      {loading && <Loader />}
      {!loading && template && projectModel && !manuallyChangingProjectModel && (
        <DndProvider backend={HTML5Backend}>
          <Editor
            model={projectModel}
            setModel={setProjectModel}
            projectId={templateId}
            project={template}
            selectedColorSwatches={{}}
            availableAdSpaces={[]}
            inProjectEditor={false}
            sourceProjectId={null}
            damStatus={damStatus}
          />
        </DndProvider>
      )}
      {!loading && projectModel && (
        <AdminEditEmailTemplateModelWindow
          open={editProjectModelWindowOpen}
          model={projectModel}
          onCloseClick={onEditProjectModelWindowClose}
          fullScreenOnMobile={true}
          onSubmit={(model) => {
            setManuallyChangingProjectModel(true);
            setProjectModel(model);
            setTimeout(() => {
              setManuallyChangingProjectModel(false);
            }, 50);
          }}
        />
      )}
    </FullSizePageContainer>
  );
};

export default EmailTemplateEditorPage;
