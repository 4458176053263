import { addDays, format, getISOWeek } from 'date-fns';
import {
  AdSpace,
  ArticleHorizontal,
  ArticleVertical,
  CtaButton,
  DownloadAttachment,
  DownloadAttachmentType,
  EmailImage,
  EmailSocialLinks,
  EmailText,
  LogoHorizontal,
  MonthCalendar,
  Placeholder,
  PrintProjectCover,
  ProjectModelComponent,
  ProjectModelComponentBase,
  ProjectModelV2,
  Promotions,
  Separator,
  UpcomingEvents
} from '../../../types';
import moment from 'moment';
import { AdminOrganization } from '../../../../../../../store/AdminOrganizations/types';

export const initialComponentFactory = (
  type: ProjectModelComponent['type'],
  model: ProjectModelV2,
  columnRef: HTMLDivElement | null,
  organization: AdminOrganization | null
) => {
  const inherited: ProjectModelComponentBase = model.defaultComponentStyle?.find((style) => style.type === type);

  switch (type) {
    case 'ad-space':
      return adSpace(columnRef);
    case 'article-horizontal':
      // inherit article-vertical styles if there is no default one for article-horizontal
      return articleHorizontal(
        (inherited
          ? inherited
          : model.defaultComponentStyle?.find((style) => style.type === 'article-vertical')) as ArticleHorizontal,
        columnRef
      );
    case 'article-vertical':
      return articleVertical(inherited as ArticleVertical, columnRef);
    case 'logo-horizontal':
    case 'logo-vertical':
      return logo(inherited as LogoHorizontal);
    case 'image':
      return image(inherited as EmailImage, columnRef);
    case 'print-project-cover':
      return printProjectCover(inherited as PrintProjectCover, columnRef);
    case 'text':
      return text(inherited as EmailText);
    case 'cta-button':
      return ctaButton(inherited as CtaButton);
    case 'download-attachment':
      // inherit cta-button style if there is no default style for download-attachment
      return downloadAttachment(
        (inherited
          ? inherited
          : model.defaultComponentStyle?.find((style) => style.type === 'cta-button')) as DownloadAttachment
      );
    case 'separator':
      return separator(inherited as Separator);
    case 'social-links':
      return socialLinks(inherited as EmailSocialLinks, organization);
    case 'placeholder':
      return placeholder(inherited as Placeholder);
    case 'upcoming-events':
      return upcomingEvents(inherited as UpcomingEvents);
    case 'month-calendar':
      return monthCalendar(inherited as MonthCalendar);
    case 'promotions':
      return promotions(inherited as Promotions);
  }
};

const getBaseComponent = (): ProjectModelComponentBase => ({
  colorSwatchIgnore: [],
  style: {},
  wrapperStyle: {}
});
const getBaseArticleParams = (
  inherited: any,
  columnRef: HTMLDivElement | null,
  type: 'article-horizontal' | 'article-vertical'
) => ({
  ...inherited,
  body:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec elementum iaculis tortor vitae finibus. Maecenas consectetur odio a dapibus.',
  image: getDefaultImage(
    // for article-horizontal set image width to be 50% of component size
    type === 'article-horizontal'
      ? columnRef?.offsetWidth
        ? columnRef.offsetWidth / 2
        : undefined
      : columnRef?.offsetWidth,
    columnRef?.offsetHeight
  ),
  customReadMore: false,
  headline: 'Heading here',
  readMoreLinkUrl: '',
  readMoreText: 'Read more',
  showReadMore: false,
  showImage: true,
  showHeadline: true
});
export const getDefaultImage = (width = 300, height = 200) => ({
  url: ``,
  containerWidth: width,
  containerHeight: height,
  width: width,
  height: height,
  positionX: 0,
  positionY: 0,
  linkUrl: '',
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  hasCaption: false,
  caption: 'Caption here'
});
const adSpace = (columnRef: HTMLDivElement | null): AdSpace => ({
  ...getBaseComponent(),
  type: 'ad-space',
  params: {
    width: columnRef?.offsetWidth ?? 300,
    height: columnRef?.offsetHeight ?? 200
  }
});
const articleHorizontal = (inherited: ArticleHorizontal, columnRef: HTMLDivElement | null): ArticleHorizontal => ({
  ...getBaseComponent(),
  ...inherited,
  params: {
    ...getBaseArticleParams(inherited, columnRef, 'article-horizontal')
  },
  type: 'article-horizontal'
});
const articleVertical = (inherited: ArticleVertical, columnRef: HTMLDivElement | null): ArticleVertical => ({
  ...getBaseComponent(),
  ...inherited,
  params: {
    ...getBaseArticleParams(inherited, columnRef, 'article-vertical')
  },
  type: 'article-vertical'
});
const logo = (inherited: LogoHorizontal): LogoHorizontal => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'logo-horizontal',
  params: getDefaultImage()
});
const image = (inherited: EmailImage, columnRef: HTMLDivElement | null): EmailImage => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'image',
  params: getDefaultImage(columnRef?.offsetWidth, columnRef?.offsetHeight)
});
const printProjectCover = (inherited: PrintProjectCover, columnRef: HTMLDivElement | null): PrintProjectCover => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'print-project-cover',
  params: getDefaultImage(299, 387),
  wrapperStyle: {
    marginTop: '10px',
    marginBottom: '10px',
    boxShadow: '#ccc 0px 0px 5px 1px'
  }
});
const text = (inherited: EmailText): EmailText => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'text',
  role: 'common',
  params: {
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>'
  }
});
const ctaButton = (inherited: CtaButton): CtaButton => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'cta-button',
  role: 'common',
  params: {
    text: 'Button',
    link: 'https://link',
    style: {
      backgroundColor: 'inherit',
      borderRadius: 'inherit',
      borderStyle: 'inherit',
      borderWidth: 'inherit',
      borderColor: 'inherit'
    }
  }
});
const downloadAttachment = (inherited: DownloadAttachment): DownloadAttachment => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'download-attachment',
  role: 'common',
  params: {
    type: DownloadAttachmentType.ALL,
    text: 'Download attachment(s)',
    attachmentId: null,
    style: {
      backgroundColor: 'inherit',
      borderRadius: 'inherit',
      borderStyle: 'inherit',
      borderWidth: 'inherit',
      borderColor: 'inherit'
    }
  }
});
const separator = (inherited: Separator): Separator => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'separator',
  params: {}
});
const socialLinks = (inherited: EmailSocialLinks, organization: AdminOrganization | null): EmailSocialLinks => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'social-links',
  params: {
    images: {
      facebook: getDefaultImage(30, 30),
      instagram: getDefaultImage(30, 30),
      linkedIn: getDefaultImage(30, 30),
      twitter: getDefaultImage(30, 30),
      youtube: getDefaultImage(30, 30)
    },
    urls: {
      facebook: organization?.facebookLink || '',
      instagram: organization?.instagramLink || '',
      twitter: organization?.twitterLink || '',
      linkedIn: organization?.linkedinLink || '',
      youtube: organization?.youtubeLink || ''
    },
    color: '#000000'
  }
});
const placeholder = (inherited: Placeholder): Placeholder => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'placeholder',
  params: {}
});

const upcomingEvents = (inherited: UpcomingEvents): UpcomingEvents => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'upcoming-events',
  params: {
    header: 'Upcoming Events',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    events: []
  }
});

const monthCalendar = (inherited: MonthCalendar): MonthCalendar => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'month-calendar',
  params: {
    calendar: [],
    date: moment().format('YYYY-MM-DD'),
    selectedStyle: null,
    styles: {
      eventBackgroundColor: '',
      eventTextColor: '',
      eventBorderRadius: 0,
      headerBackgroundColor: '',
      headerTextColor: '',
      borderColor: '',
      backgroundColor: '',
      backgroundPrevNextColor: '',
      dayBackgroundColor: '',
      dayTextColor: '',
      dayBorderRadius: 0,
      dayPosition: 'l'
    },
    viewType: 'month',
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    week: getISOWeek(new Date()),
    labelPosition: 'left'
  }
});

const promotions = (inherited: Promotions): Promotions => ({
  ...getBaseComponent(),
  ...inherited,
  type: 'promotions',
  params: {
    header: 'Promotions',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    promotions: [],
    style: {
      backgroundColor: 'transparent',
      color: '#000',
      borderRadius: 'inherit',
      borderStyle: 'inherit',
      borderWidth: 'inherit',
      borderColor: 'inherit',
      height: '5px'
    }
  }
});
