import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import { MenuItem } from '@material-ui/core';
import { AdminSelectInput } from '../../Admin/Shared/AdminFormInputs';
import { useTypedSelector } from '../../../../utils';
import { authOperations } from '../../../../store/Auth';
import { Row } from '../../../Shared/StyledComponents';

type SwitchOrganizationWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const SwitchOrganizationWindow: FunctionComponent<SwitchOrganizationWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);
  const organizations = useTypedSelector((state) => state.auth.organizations);

  const onSubmit = async (values: { organizationId: string }) => {
    try {
      await dispatch(authOperations.switchOrganization(values.organizationId));
      await dispatch(authOperations.validateToken());
      onCloseClick();
      Toast.success(t('notifications.myOrganization.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  const formInputs = () => (
    <>
      <Row>{t('pages.myOrganization.windows.switchOrganizationInfo')}</Row>
      <AdminSelectInput t={t} name="organizationId" section="myOrganization">
        {organizations.map((organization) => (
          <MenuItem key={organization.id} value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.ok')}
      header={t('pages.myOrganization.windows.switchOrganization')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ organizationId: currentOrganizationId }}
      validationSchema={Yup.object().shape({
        organizationId: Yup.string().required()
      })}
    />
  );
};

export default SwitchOrganizationWindow;
