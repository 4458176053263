import styled from 'styled-components';
import { Accordion, Paper } from '@material-ui/core';

export const PageContainer = styled.div`
  padding: 2rem 3rem 5rem;
`;

export const FullSizePageContainer = styled.div`
  padding: 0;
  overflow: hidden;
  height: 100%;
`;

export const PageTitle = styled.div`
  font-weight: bold;
  font-size: 2rem;
`;

export const RegularTitle = styled.div`
  font-size: 1.4rem;
`;

export const PageContent = styled.div`
  margin-top: 3rem;
`;

export const PageContentPaperHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const PageContentPaper = styled(Paper)`
  position: relative;
  padding: 2rem;
  margin: 1rem 0;
`;

export const PageContentTextEditor = styled.div`
  position: relative;
  margin-top: 23px;
  padding-top: 15px;
  padding-bottom: 3rem;
  overflow: auto;
  height: calc(100% - 6rem - 38px);
  display: inline-block;
  transition: all 0.5s ease-in-out;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
`;

export const PageContentTextEditorSidebar = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 38px;
  margin-bottom: 48px;
  height: calc(100% - 6rem - 38px - 48px);
  display: inline-block;
  transition: all 0.5s ease-in-out;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const NoResultsFound = styled.div`
  width: 100%;
  font-style: italic;
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem;
`;

export const InfoText = styled.div`
  width: 100%;
  font-style: italic;
  font-size: 0.8rem;
  opacity: 0.8;
`;

export const ToolSpacer = styled.div`
  width: 20px;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root.Mui-disabled {
    opacity: 0.7;
    background-color: #fff;
  }
`;
