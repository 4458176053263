import React from 'react';
import { ArticleHorizontal, ArticleVertical, ImageData, ImageDropObjectData } from '../../../types';
import WysiwygEditor from './WysiwygEditor';
import ImageElement from './ImageElement';
import { ImageProcessingResponse } from '../../../../../../../store/Images/types';
import { ColorSwatchType, MyOrganizationSwatchColor } from '../../../../../../../store/MyOrganization/types';
import { extractColorsFromSwatches } from '../../../Utils';

export const getReadMoreLinkElement = (
  data: ArticleHorizontal | ArticleVertical,
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor }
) => {
  if (!data.params.showReadMore) {
    return null;
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={data.params.customReadMore ? data.params.customReadMoreLink : data.params.readMoreLinkUrl}
      style={{
        ...data.articleReadMoreStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, [ColorSwatchType.link, ColorSwatchType.button])
      }}
    >
      {(data.params.customReadMore
        ? data.params.customReadMoreText
        : (data.params.readMoreText || '').replace('&gt;', '>')) || 'Read More'}
    </a>
  );
};

export const getHeadlineElement = (
  data: ArticleHorizontal | ArticleVertical,
  onChange: (data: ArticleHorizontal | ArticleVertical) => void,
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor },
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>
) => {
  return (
    <div
      style={{
        ...data.articleHeadlineStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, [ColorSwatchType.heading]),
        display: 'flex'
      }}
      className={data.articleHeadlineClasses}
    >
      {data.params.showHeadlineImage && data.params.headlineImage && (
        <div style={{ marginRight: 10 }}>
          <ImageElement
            data={data.params.headlineImage}
            onChange={(updatedImageData) =>
              onChange({
                ...data,
                params: {
                  ...data.params,
                  headlineImage: updatedImageData
                }
              })
            }
            onImageDrop={onImageDrop}
            placeholderText={'Drop Image Here...'}
            type={data.type}
            styles={{ fontSize: '10px' }}
          />
        </div>
      )}
      <div style={{ width: '100%' }}>
        <WysiwygEditor
          inline
          content={data.params.headline || '<p></p>'}
          onBlur={(newContent) =>
            onChange({
              ...data,
              params: {
                ...data.params,
                headline: newContent
              }
            })
          }
          onFocus={(contentColor) => {
            if (
              data.articleHeadlineStyle &&
              data.articleHeadlineStyle.color &&
              data.articleHeadlineStyle.color === contentColor
            ) {
              return;
            }
            onChange({
              ...data,
              articleHeadlineStyle: {
                ...(data.articleHeadlineStyle || {}),
                color: contentColor
              }
            });
          }}
        />
      </div>
    </div>
  );
};

export const getBodyElement = (
  data: ArticleHorizontal | ArticleVertical,
  onChange: (data: ArticleHorizontal | ArticleVertical) => void,
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor }
) => {
  return (
    <div
      style={{
        ...data.articleBodyStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, [ColorSwatchType.paragraph])
      }}
      className={data.articleBodyClasses}
    >
      <WysiwygEditor
        inline
        content={data.params.body || '<p></p>'}
        onBlur={(newContent) =>
          onChange({
            ...data,
            params: {
              ...data.params,
              body: newContent
            }
          })
        }
        onFocus={(contentColor) => {
          if (data.articleBodyStyle && data.articleBodyStyle.color && data.articleBodyStyle.color === contentColor) {
            return;
          }
          onChange({
            ...data,
            articleBodyStyle: {
              ...(data.articleBodyStyle || {}),
              color: contentColor
            }
          });
        }}
      />
    </div>
  );
};

export const getImageElement = (
  data: ArticleHorizontal | ArticleVertical,
  onChange: (data: ArticleHorizontal | ArticleVertical) => void,
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>,
  applyingStory: boolean
) => {
  if (!data.params.image || (data.params.showImage !== undefined && !data.params.showImage)) {
    return null;
  }

  return (
    <ImageElement
      data={data.params.image}
      applyingStory={applyingStory}
      onChange={(updatedImageData) =>
        onChange({
          ...data,
          params: {
            ...data.params,
            image: updatedImageData
          }
        })
      }
      onImageDrop={onImageDrop}
      placeholderText={'Drop Image Here...'}
      type={data.type}
    />
  );
};
