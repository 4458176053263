import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../Shared/Window/ModalConfirmationWindow';
import { authOperations } from '../../../store/Auth';
import { useDispatch } from 'react-redux';
import { Row } from '../../Shared/StyledComponents';
import { useTypedSelector } from '../../../utils';
import { SystemSettingType } from '../../../store/SystemSettings/types';
import { getSecondsThatLeft, getTimeToShowModal } from '../../../utils/userLatestActivity';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import { getUserMainPage } from '../../../utils/redirection';
import app from '../../../config/app/app';

type InactivityAutoLogOutWindowProps = {};

const InactivityAutoLogOutWindow: FunctionComponent<InactivityAutoLogOutWindowProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [kickedOutFromProject, setKickedOutFromProject] = useState(false);
  const [secondsToLogOut, setSecondsToLogOut] = useState(0);
  const [secondsToLeaveProject, setSecondsToLeaveProject] = useState(0);
  const [alertType, setAlertType] = useState<'logout' | 'project' | null>(null);
  const authenticated = useTypedSelector((state) => state.auth.authenticated);
  const systemSettings = useTypedSelector((state) => state.auth.systemSettings);
  const user = useTypedSelector((state) => state.auth.user);
  const systemSettingsLogoutTime = parseInt(
    systemSettings.find((setting) => setting.type === SystemSettingType.LOGOUT_TIME_INACTIVITY)?.value || '120'
  );
  const systemSettingsProjectLeaveTime = parseInt(
    systemSettings.find((setting) => setting.type === SystemSettingType.PROJECT_LEAVE_INACTIVITY_TIME)?.value || '60'
  );

  let isOnPublicRoute = false;
  try {
    isOnPublicRoute = app.publicPageLayoutRoutes.includes(window.location.pathname.slice(1));
  } catch (e) {
    isOnPublicRoute = false;
  }

  let checkTimeInterval: null | number = null;

  const timeToShowLogOutModal = getTimeToShowModal(systemSettingsLogoutTime);
  const timeToShowProjectLeaveModal = getTimeToShowModal(systemSettingsProjectLeaveTime);

  useEffect(() => {
    if (!isOnPublicRoute && authenticated && (systemSettingsLogoutTime > 0 || systemSettingsProjectLeaveTime > 0)) {
      checkTimeInterval = setInterval(() => {
        const secondsToLogOut = getSecondsThatLeft(systemSettingsLogoutTime);
        const secondsToLeaveProjectComputed = getSecondsThatLeft(systemSettingsProjectLeaveTime);

        const shouldShowLeaveProjectModal =
          timeToShowProjectLeaveModal > 0 &&
          !!window.location.pathname.match(/\/newsletter|bulletin\/\w+-\w+-\w+-\w+-\w+\/editor/) &&
          secondsToLeaveProjectComputed <= timeToShowProjectLeaveModal;
        const shouldShowLogOutModal = timeToShowLogOutModal > 0 && secondsToLogOut <= timeToShowLogOutModal;

        // console.log('shouldShowLogOutModal', shouldShowLogOutModal);
        // console.log('secondsToLogOut', secondsToLogOut);
        // console.log('timeToShowLogOutModal', timeToShowLogOutModal);
        // console.log('&');
        // console.log('shouldShowLeaveProjectModal', shouldShowLeaveProjectModal);
        // console.log('secondsToLeaveProjectComputed', secondsToLeaveProjectComputed);
        // console.log('timeToShowProjectLeaveModal', timeToShowProjectLeaveModal);
        // console.log('=====================');

        if (shouldShowLogOutModal && secondsToLogOut <= 0) {
          dispatch(authOperations.logout());
          setAlertType('logout');
          setLoggedOut(true);
          return;
        }

        if (shouldShowLeaveProjectModal && secondsToLeaveProjectComputed <= 0) {
          setAlertType('project');
          setKickedOutFromProject(true);
          dispatch(push(getUserMainPage(user)));
          return;
        }

        if (shouldShowLeaveProjectModal || shouldShowLogOutModal) {
          setOpen(true);

          if (shouldShowLogOutModal) {
            setSecondsToLogOut(secondsToLogOut);
            setAlertType('logout');
            return;
          }

          if (shouldShowLeaveProjectModal) {
            setSecondsToLeaveProject(secondsToLeaveProjectComputed);
            setAlertType('project');
            return;
          }
        }
      }, 1000);
    } else {
      if (checkTimeInterval) {
        clearInterval(checkTimeInterval);
      }
    }

    return () => {
      if (checkTimeInterval) {
        clearInterval(checkTimeInterval);
      }
    };
  }, [authenticated, isOnPublicRoute]);

  const onSubmit = async () => {
    if (loggedOut || kickedOutFromProject) {
      setOpen(false);
      setLoggedOut(false);
      setKickedOutFromProject(false);
      setAlertType(null);
    } else {
      try {
        await dispatch(authOperations.validateToken());
        setOpen(false);
      } catch (e) {
        // network error
      }
    }
  };

  const getHeaderText = () => {
    if (alertType === 'logout') {
      return loggedOut
        ? t('windows.inactivityAutoLogOutWindow.loggedOutTitle')
        : t('windows.inactivityAutoLogOutWindow.soonWillBeLoggedOutTitle');
    }

    if (alertType === 'project') {
      return t('windows.inactivityAutoLogOutWindow.projectInactivityTitle');
    }

    return '';
  };

  const getOkButtonText = () => {
    if (alertType === 'logout') {
      return loggedOut ? t('common.ok') : t('windows.inactivityAutoLogOutWindow.keepMeSignedIn');
    }

    if (alertType === 'project') {
      return kickedOutFromProject ? t('common.ok') : t('windows.inactivityAutoLogOutWindow.keepMeInProject');
    }

    return '';
  };

  const getContent = () => {
    if (alertType === 'logout') {
      return (
        <>
          {!loggedOut && (
            <Row>{t('windows.inactivityAutoLogOutWindow.soonWillBeLoggedOutContent', { secondsToLogOut })}</Row>
          )}
          {loggedOut && <Row>{t('windows.inactivityAutoLogOutWindow.loggedOutContent')}</Row>}
        </>
      );
    }

    if (alertType === 'project') {
      return (
        <>
          {!kickedOutFromProject && (
            <Row>
              {t('windows.inactivityAutoLogOutWindow.soonWillBeKickedOutFromProjectContent', {
                secondsToLeaveProject
              })}
            </Row>
          )}
          {kickedOutFromProject && <Row>{t('windows.inactivityAutoLogOutWindow.kickedOutFromProjectContent')}</Row>}
        </>
      );
    }

    return '';
  };

  return (
    <ModalConfirmationWindow
      onCloseClick={() => null}
      open={open}
      onSubmit={onSubmit}
      header={getHeaderText()}
      okButtonText={getOkButtonText()}
      disableBackdropClick={true}
      disableCloseButton={true}
    >
      {getContent()}
    </ModalConfirmationWindow>
  );
};

export default InactivityAutoLogOutWindow;
