import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  LoginFormValues,
  MyAccountUpdatePayload,
  ResendVerificationRequestPayload,
  ResetPasswordInstructionsRequestPayload,
  SetChangePasswordRequestPayload,
  SetPasswordRequestParameters,
  SetPasswordRequestPayload,
  SignUpFormValues,
  OrganizationData,
  ValidateAuthTicketRequestParams,
  VerifyUserRequestParameters,
  VerifyUserRequestPayload
} from './types';

export const logout = (): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/auth/logout'
  }
});

export const validateToken = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/auth/token/validate'
  }
});

export const login = (settings: RequestSettings<{}, LoginFormValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/auth/login'
  },
  payload: settings.requestPayload
});

export const sendResetPasswordInstructions = (
  settings: RequestSettings<{}, ResetPasswordInstructionsRequestPayload>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/auth/ticket/password'
  },
  payload: settings.requestPayload
});

export const resendVerification = (settings: RequestSettings<{}, ResendVerificationRequestPayload>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/auth/ticket/verification'
  },
  payload: settings.requestPayload
});

export const signUp = (settings: RequestSettings<{}, SignUpFormValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/auth/register'
  },
  payload: settings.requestPayload
});

export const verifyUser = (
  settings: RequestSettings<VerifyUserRequestParameters, VerifyUserRequestPayload>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/auth/ticket/verification/:ticketId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const setPassword = (
  settings: RequestSettings<SetPasswordRequestParameters, SetPasswordRequestPayload>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/auth/ticket/password/:ticketId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const changePassword = (settings: RequestSettings<{}, SetChangePasswordRequestPayload>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/auth/password/change'
  },
  payload: settings.requestPayload
});

export const validateAuthTicket = (settings: RequestSettings<ValidateAuthTicketRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/auth/ticket/:ticketType/:ticketId/validate'
  },
  params: settings.requestParams
});

export const getOrganizationData = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization'
  }
});

export const getOrganizationSocialAccountsData = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/social'
  }
});

export const getOrganizationLogo = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/logo?type=MAIN'
  }
});

export const setOrganizationData = (settings: RequestSettings<{}, OrganizationData>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/organization'
  },
  payload: settings.requestPayload
});

export const updateAccount = (settings: RequestSettings<{}, MyAccountUpdatePayload>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/auth/account'
  },
  payload: settings.requestPayload
});

export const uploadMainLogo = (settings: { requestPayload: FormData }): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/organization/upload_main_logo'
  },
  payload: settings.requestPayload
});

export const removeMainLogo = (settings: RequestSettings<{}, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/organization/remove_main_logo'
  },
  payload: settings.requestPayload
});

export const switchOrganization = (settings: RequestSettings<{ organizationId: string }>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/auth/switch_organization/:organizationId'
  },
  params: settings.requestParams
});
