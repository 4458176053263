import React, { FunctionComponent } from 'react';
import {
  EmailImage,
  ImageData,
  ImageDropObjectData,
  LogoHorizontal,
  LogoVertical,
  PrintProjectCover
} from '../../types';
import ImageElement from './Utils/ImageElement';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';

type ImageProps = {
  data: EmailImage | LogoHorizontal | LogoVertical | PrintProjectCover;
  onChange: (data: EmailImage | LogoHorizontal | LogoVertical | PrintProjectCover) => void;
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>;
};

const Image: FunctionComponent<ImageProps> = ({ data, onChange, onImageDrop }) => {
  return (
    <ImageElement
      data={data.params}
      onChange={(updatedImageData) =>
        onChange({
          ...data,
          params: updatedImageData
        })
      }
      onImageDrop={onImageDrop}
      styles={{
        ...(data.style || {}),
        ...(data.wrapperStyle || {})
      }}
      placeholderText={data.type === 'print-project-cover' ? 'Print Project Cover Will Go Here' : 'Drop Image Here...'}
    />
  );
};

export default Image;
