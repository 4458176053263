import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import SetPasswordForm from '../../Auth/ValidateAndSetPassword/SetPasswordForm';
import { ChangePasswordFormValues } from '../../../../store/Auth/types';
import { createNetworkErrorObject } from '../../../../utils';
import Toast from '../../../Shared/Toast/Toast';
import { authOperations } from '../../../../store/Auth';
import { PasswordPolicy } from '../../../../store/SystemSettings/types';
import Loader from '../../../Shared/Loading/Loader';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { useDispatch } from 'react-redux';

type ChangePasswordWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const ChangePasswordWindow: FunctionComponent<ChangePasswordWindowProps> = ({ onCloseClick, open }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formSubmitError, setFormSubmitError] = useState<NetworkError | null>(null);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(null);

  useEffect(() => {
    const fetchPasswordPolicy = async () => {
      setPasswordPolicy(await myOrganizationOperations.getPasswordPolicy());
    };
    if (open) {
      setFormSubmitError(null);
      fetchPasswordPolicy();
    }
  }, [open]);

  const onSubmit = async (values: ChangePasswordFormValues) => {
    setFormSubmitError(null);
    try {
      await authOperations.changePassword(values);
      await dispatch(authOperations.validateToken());

      Toast.success(t('notifications.myAccount.successOperation'));
      onCloseClick();
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      setFormSubmitError(networkError);
      // Toast.error(t('notifications.myAccount.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myAccount.windows.changePassword')}
      onCloseClick={onCloseClick}
      hideActionButtons
    >
      {!passwordPolicy && <Loader />}
      {passwordPolicy && (
        <SetPasswordForm
          handleSubmit={onSubmit}
          formSubmitError={formSubmitError}
          withPreviousPassword
          passwordPolicy={passwordPolicy}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default ChangePasswordWindow;
