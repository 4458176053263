import React, { useEffect, useState } from 'react';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  StoryEmailChannelPriority,
  StoryEmailChannelValues,
  StoryFieldName,
  StoryFieldType,
  StoryPrintChannelValues,
  StorySimpleSocialChannelValues,
  storyChannelTypes
} from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import { Box, Button, Grid, Menu, MenuItem, Theme, Tooltip, makeStyles } from '@material-ui/core';
import { hasPermission } from '../../../../utils/permissions';
import {
  isChannelDisabled,
  getChannelBodyCharLimit,
  getChannelFields,
  getOrganizationChannelTypes,
  isHtmlFieldType,
  newLinesToParagraphs,
  getChannelFileMimeTypes
} from '../Utils/storyUtils';
import PublishBlogChannelWindow from '../Windows/PublishBlogChannelWindow';
import PublishSocialChannelWindow from '../Windows/PublishSocialChannelWindow';
import StoryChannelPublishFailedWindow from '../Windows/StoryChannelPublishFailedWindow';
import BlogChannelForm from './BlogChannelForm';
import EmailChannelForm from './EmailChannelForm';
import PrintChannelForm from './PrintChannelForm';
import SimpleSocialChannelForm from './SimpleSocialChannelForm';
import Toast from '../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../utils';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { DesignerStory } from '../../../../store/PrintProjects/types';
import PrintArticlesWindow from '../Windows/PrintArticlesWindow';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import ChannelLatestChange from './ChannelLatestChange';
import ChannelStatusButtons from './ChannelStatusButtons';
import GenerateAiContentWindow from '../Windows/GenerateAiContentWindow';
import GenerateAiImageWindow from '../Windows/GenerateAiImageWindow';
import { AntTab, AntTabs } from '../ManageStoryPage';
import ChannelAttachments from './Attachments/ChannelAttachments';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: 15
  },
  padding: {
    padding: theme.spacing(3)
  },
  tabs: {
    // backgroundColor: theme.palette.background.paper
  }
}));

type ChannelFormProps = {
  storyId: string;
  channelId: string;
  channel: StoryChannel;
  channelsData: StoryChannel[];
  channelType: StoryChannelType;
  expanded: StoryChannelType | false;
  handleChannelChange: (channel: StoryChannelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast: boolean,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => void;
  isOwnerOfStory: boolean;
  disabled: boolean;
  fetchStoryData: () => Promise<React.ReactText | undefined>;
};

const ChannelForm: React.FC<ChannelFormProps> = ({
  storyId,
  channelId,
  channelsData,
  channelType,
  isOwnerOfStory,
  expanded,
  setChannelStatus,
  fetchStoryData
}) => {
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);
  const role = useTypedSelector((state) => state.auth.role);
  const [channelData, setChannelData] = useState<StoryChannel | undefined>(
    channelsData.find((channel) => channel.type === channelType)
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [publishSocialPostWindowOpen, onPublishSocialPostWindowOpen, onPublishSocialPostWindowClose] = useOpenHandler();
  const [publishBlogPostWindowOpen, onPublishBlogPostWindowOpen, onPublishBlogPostWindowClose] = useOpenHandler();
  const [publishErrorWindowOpen, onPublishErrorWindowOpen, onPublishErrorWindowClose] = useOpenHandler();
  const [printArticlesWindowOpen, onPrintArticlesWindowOpen, onPrintArticlesWindowClose] = useOpenHandler();
  const [generateAiContentWindowOpen, onGenerateAiContentWindowOpen, onGenerateAiContentWindowClose] = useOpenHandler();
  const [generateAiImageWindowOpen, onGenerateAiImageWindowOpen, onGenerateAiImageWindowClose] = useOpenHandler();
  const [copyingPrintArticle, setCopyingPrintArticle] = useState<boolean>(false);
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    setChannelData(channelsData.find((channel) => channel.type === channelType));
  }, [setChannelStatus]);

  const handleChannelTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleGenerateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onGenerateAiContentWindowOpen();
  };

  const handleGenerateImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onGenerateAiImageWindowOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyPrintArticle = async (article: DesignerStory) => {
    if (!channelData) {
      setAnchorEl(null);
      return;
    }

    setCopyingPrintArticle(true);
    try {
      const copiedChannelData = await storiesOperations.copyPrintArticle(
        channelData.id,
        article,
        getChannelFields(channelData.type)
      );

      setChannelData(copiedChannelData);
      Toast.success(t('notifications.story.printArticleCopied'));
    } catch (e) {
      Toast.error(t('notifications.story.printArticleErrorCopy'));
    } finally {
      setCopyingPrintArticle(false);
    }
  };

  const handleCopyAllData = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>, type: StoryChannelType) => {
    e.stopPropagation();
    const channelToCopy = channelsData.find((channel) => channel.type === type);

    if (!channelToCopy || !channelData) {
      setAnchorEl(null);
      return;
    }

    try {
      const copiedChannelData = await storiesOperations.copyChannel(
        channelData.id,
        channelToCopy.id,
        getChannelFields(channelData.type)
      );

      setChannelData(copiedChannelData);
      Toast.success(t('notifications.story.storyChannelCopied'));
    } catch (e) {
      Toast.error(t('notifications.story.errorCopy'));
    }
    setAnchorEl(null);
  };

  const createOrUpdateChannel = async (
    channelType: StoryChannelType,
    formData: StoryPrintChannelValues | StoryEmailChannelValues | StorySimpleSocialChannelValues,
    showToast?: boolean
  ) => {
    if (storyId) {
      try {
        const data = Object.entries(formData).map(([name, value]) => ({
          type: StoryFieldType.STRING,
          name: parseInt(name),
          value: value
        }));

        if (channelId) {
          await storiesOperations.updateChannel(channelId, data);
        } else {
          await storiesOperations.createChannel(storyId, channelType, data);
        }

        setChannelData((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              fields: data
            };
          } else {
            return prevState;
          }
        });

        if (showToast) {
          Toast.success(t('notifications.story.storyChannelSaved'));
        }
      } catch (e) {
        Toast.error(t('notifications.story.errorSave'));
      }
    }
  };

  const shouldDisplayCopyButton = (channelData?: StoryChannel) => {
    if (
      !channelData ||
      isChannelDisabled(channelData, isOwnerOfStory) ||
      !hasPermission(authState.role, ['storiesEditChannels'])
    ) {
      return false;
    }
    return true;
  };

  const shouldDisplayGenerateButton = (channelData?: StoryChannel) => {
    if (
      !channelData ||
      isChannelDisabled(channelData, isOwnerOfStory) ||
      !(
        hasPermission(authState.role, ['storiesEditChannels']) && hasPermission(authState.role, ['storiesAiContent'])
      ) ||
      !isFormValid
    ) {
      return false;
    }
    return true;
  };

  const getChannelForm = (channelData: StoryChannel | undefined, channelType: StoryChannelType) => {
    if (!channelData) return null;

    switch (channelType) {
      case StoryChannelType.PRINT:
        return (
          <PrintChannelForm
            key={channelId}
            storyId={storyId}
            channelId={channelId}
            isOwnerOfStory={isOwnerOfStory}
            expanded={expanded}
            channelData={channelData}
            submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
            setIsFormValid={setIsFormValid}
          />
        );

      case StoryChannelType.EMAIL:
        return (
          <EmailChannelForm
            key={channelId}
            storyId={storyId}
            isOwnerOfStory={isOwnerOfStory}
            channelId={channelId}
            expanded={expanded}
            channelData={channelData}
            submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
            setIsFormValid={setIsFormValid}
          />
        );

      case StoryChannelType.BLOG:
        return (
          <>
            <BlogChannelForm
              key={channelId}
              storyId={storyId}
              isOwnerOfStory={isOwnerOfStory}
              channelId={channelId}
              expanded={expanded}
              channelData={channelData}
              submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
              setIsFormValid={setIsFormValid}
            />
            <PublishBlogChannelWindow
              type={channelType}
              channel={channelData}
              open={publishBlogPostWindowOpen}
              onCloseClick={onPublishBlogPostWindowClose}
              onSubmit={() => setChannelStatus(channelData, StoryChannelStatus.PUBLISHED, false)}
              fullScreenOnMobile
            />
          </>
        );

      case StoryChannelType.FACEBOOK:
      case StoryChannelType.TWITTER:
      case StoryChannelType.INSTAGRAM:
      case StoryChannelType.LINKEDIN:
        return (
          <>
            <SimpleSocialChannelForm
              key={channelId}
              type={channelType}
              isOwnerOfStory={isOwnerOfStory}
              charLimit={getChannelBodyCharLimit(channelType)}
              storyId={storyId}
              channelId={channelId}
              expanded={expanded}
              channelData={channelData}
              submitForm={(data, showToast) => createOrUpdateChannel(channelType, data, showToast)}
              setIsFormValid={setIsFormValid}
            />
            <PublishSocialChannelWindow
              type={channelType}
              channel={channelData}
              open={publishSocialPostWindowOpen}
              onCloseClick={onPublishSocialPostWindowClose}
              onSubmit={() => setChannelStatus(channelData, StoryChannelStatus.SCHEDULED, false)}
              fullScreenOnMobile
            />
            <StoryChannelPublishFailedWindow
              channel={channelData}
              storyId={storyId}
              open={publishErrorWindowOpen}
              onCloseClick={onPublishErrorWindowClose}
              onSuccessfulSubmit={() => setChannelStatus(channelData, StoryChannelStatus.IDLE, false)}
              fullScreenOnMobile
            />
          </>
        );
    }
  };

  return (
    <>
      {channelData && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginBottom="-40px"
          zIndex={9}
          position="relative"
          marginLeft="230px"
        >
          <ChannelLatestChange channel={channelData} />
          <ChannelStatusButtons
            channel={channelData}
            isOwnerOfStory={isOwnerOfStory}
            setChannelStatus={setChannelStatus}
            onPublishWindowOpen={() => {
              if (channelType === StoryChannelType.BLOG) {
                onPublishBlogPostWindowOpen();
              } else {
                onPublishSocialPostWindowOpen();
              }
            }}
            onPublishErrorWindowOpen={onPublishErrorWindowOpen}
            isFormValid={isFormValid}
            expanded={expanded}
            disabled={!getOrganizationChannelTypes(authState.organization).includes(channelType)}
          />
        </Box>
      )}
      <div className={classes.root}>
        <div className={classes.tabs}>
          <AntTabs value={tabValue} onChange={handleChannelTabChange}>
            <AntTab label={t('pages.story.tabs.content')} />
            <AntTab
              label={t('pages.story.tabs.attachments', { count: channelData && channelData.attachments.length })}
            />
          </AntTabs>
        </div>
      </div>
      {copyingPrintArticle && <LoadingModal message={t('pages.story.copyingPrintArticle')} />}
      {tabValue === 0 && (
        <>
          <div>
            {shouldDisplayCopyButton(channelData) && (
              <>
                <Button variant="outlined" size="small" onClick={handleClick}>
                  {t('pages.story.copyAllDataFrom')}
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onPrintArticlesWindowOpen();
                      setAnchorEl(null);
                    }}
                  >
                    {t('pages.story.printArticle')}
                  </MenuItem>
                  {storyChannelTypes.map((storyChannelType) => {
                    if (
                      channelType !== storyChannelType &&
                      channelsData.find(
                        (channel) => channel.type === storyChannelType && channel.status !== StoryChannelStatus.REJECTED
                      )
                    ) {
                      return (
                        <MenuItem
                          onClick={(e) => handleCopyAllData(e, storyChannelType)}
                          key={`menu-item-${storyChannelType}`}
                        >
                          {storyChannelType === StoryChannelType.PRINT
                            ? t('pages.story.printStory')
                            : t(`pages.story.channels.${storyChannelType}`)}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </>
            )}
            {shouldDisplayGenerateButton(channelData) && channelData && (
              <Tooltip title={`${t(`pages.story.generateAiContentTooltip.${channelData.type}`)}`} placement="top" arrow>
                <Button variant="outlined" size="small" onClick={handleGenerateClick} style={{ marginLeft: 10 }}>
                  {t('pages.story.generateAiContent')}
                </Button>
              </Tooltip>
            )}
          </div>
          <div>{getChannelForm(channelData, channelType)}</div>
        </>
      )}
      {tabValue === 1 && (
        <>
          {channelData && (
            <Grid container>
              <Grid item xs={12}>
                <ChannelAttachments
                  mimeTypes={getChannelFileMimeTypes(channelData.type)}
                  initialAttachments={channelData.attachments}
                  channelId={channelId}
                  storyId={storyId}
                  disabled={
                    isChannelDisabled(channelData, isOwnerOfStory) || !hasPermission(role, ['storiesEditChannels'])
                  }
                  channelType={channelData.type}
                  generateAiImageButton={shouldDisplayGenerateButton(channelData)}
                  handleGenerateImageClick={handleGenerateImageClick}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {printArticlesWindowOpen && (
        <PrintArticlesWindow
          open={printArticlesWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onPrintArticlesWindowClose}
          onSuccessfulSubmit={handleCopyPrintArticle}
        />
      )}
      {generateAiContentWindowOpen && channelData && (
        <GenerateAiContentWindow
          channelType={channelType}
          channelData={channelData}
          open={generateAiContentWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onGenerateAiContentWindowClose}
          onCopyToChannelClick={(generatedContent) => {
            if (channelData) {
              setChannelData((prevState) => {
                if (prevState) {
                  const updatedFields = prevState.fields.map((field) => {
                    const generatedField = generatedContent.find((genField) => genField.name === field.name);
                    return generatedField
                      ? {
                          ...field,
                          value:
                            field.name === StoryFieldName.BODY && isHtmlFieldType(channelType)
                              ? newLinesToParagraphs(generatedField.value)
                              : generatedField.value
                        }
                      : field;
                  });

                  return { ...prevState, fields: updatedFields };
                }
                return prevState;
              });
            }
            onGenerateAiContentWindowClose();
          }}
        />
      )}
      {generateAiImageWindowOpen && channelData && (
        <GenerateAiImageWindow
          channelType={channelType}
          channelData={channelData}
          open={generateAiImageWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onGenerateAiImageWindowClose}
          onGenerationDone={() => {
            onGenerateAiImageWindowClose();
            fetchStoryData();
          }}
          userId={authState.user.id}
        />
      )}
    </>
  );
};

export default ChannelForm;
