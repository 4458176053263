import React, { FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';
import SignedInRoutesContainer from './SignedInRoutesContainer';
import styled from 'styled-components';
import Sidebar from '../../Shared/Sidebar/Sidebar';
import { withTheme } from '@material-ui/core';
import { getPasswordExpirationData, useTypedSelector } from '../../../utils';
import PasswordExpiredWindow from './Windows/PasswordExpiredWindow';
import NewVersionNotification from '../../Shared/NewVersionNotification';
import { isTheme } from '../../../utils/environment';
import { APP_THEME } from '../../../typings/environment';
import VideoPreview from '../../Shared/Window/VideoPreview';

type SignedInLayoutProps = {};

const SignedInLayout: FunctionComponent<SignedInLayoutProps> = () => {
  const user = useTypedSelector((state) => state.auth.user);
  const passwordPolicy = useTypedSelector((state) => state.auth.passwordPolicy);
  const [passwordExpiredWindowOpen, setPasswordExpiredWindowOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    localStorage.getItem('sc-sidebar-collapsed') === 'true' ? true : false
  );

  useEffect(() => {
    if (passwordPolicy) {
      const { daysToExpire, rotationEnabled } = getPasswordExpirationData(passwordPolicy, user);

      if (rotationEnabled && daysToExpire <= 0) {
        setPasswordExpiredWindowOpen(true);
      } else {
        setPasswordExpiredWindowOpen(false);
      }
    }
  }, [passwordPolicy]);

  return (
    <SignedInContainer collapsed={isSidebarCollapsed}>
      <NewVersionNotification />
      <SidebarArea>
        <Sidebar
          setCollapsed={setIsSidebarCollapsed}
          collapsed={isSidebarCollapsed}
          hasIcons={isTheme(APP_THEME.NHM)}
          canCollapse={isTheme(APP_THEME.NHM)}
        />
      </SidebarArea>
      <ContentArea>
        <SignedInRoutesContainer />
      </ContentArea>

      <PasswordExpiredWindow open={passwordExpiredWindowOpen} passwordPolicy={passwordPolicy} />

      <VideoPreview />
    </SignedInContainer>
  );
};

type SignedInContainerProps = HTMLAttributes<HTMLDivElement> & { collapsed: boolean };

const SignedInContainer = styled.div<SignedInContainerProps>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${(props) => (props.collapsed ? '80px' : '230px')} 1fr;
  grid-template-areas:
    'system-update system-update'
    'sidebar content';
  height: 100%;
  overflow: hidden;
`;

const SidebarArea = withTheme(styled.div`
  grid-area: sidebar;
  background: ${({ theme }) => theme.palette.primary.main};
  height: 100%;
  overflow: auto;
`);

const ContentArea = withTheme(styled.div`
  grid-area: content;
  height: 100%;
  overflow: auto;
  color: ${({ theme }) => theme.palette.primary.main};
`);

export default SignedInLayout;
