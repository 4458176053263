import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Shared/Loading/Loader';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import app from '../../../../config/app/app';
import { OrganizationData } from '../../../../store/Auth/types';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import { authOperations } from '../../../../store/Auth';
import Toast from '../../../Shared/Toast/Toast';
import { useTypedSelector } from '../../../../utils';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isOrganizationOwner, isSystemOwner } from '../../../../utils/permissions';
import { useDispatch } from 'react-redux';

type OrganizationLogoFormProps = {};

const OrganizationLogoForm: FunctionComponent<OrganizationLogoFormProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);
  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const gridSpacing = useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 3;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await authOperations.getOrganizationData();

        setOrganizationData(data);
      } catch (e) {
        Toast.error(t('notifications.getBusinessUserData.error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentOrganizationId]);

  const saveOrganizationData = async (values: OrganizationData, props: FormikHelpers<any>) => {
    try {
      await authOperations.setOrganizationData(values);
      await dispatch(authOperations.validateToken());
      Toast.success(t('notifications.setBusinessUserData.success'));
      props.resetForm({ values });
    } catch (e) {
      Toast.error(t('notifications.setBusinessUserData.error'));
    }
  };

  const inputsDisabled = !isOrganizationOwner(authState);

  const renderOrganizationDataForm = (props: FormikProps<OrganizationData>) => (
    <Form>
      <Typography variant="h6" gutterBottom>
        {t('pages.myAccount.contactInformation')}
      </Typography>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      <AdminTextInput disabled={inputsDisabled} t={t} name="name" section="myAccount" />
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="contactPerson" section="myAccount" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="contactEmail" section="myAccount" />
        </Grid>
      </Grid>
      <hr style={{ marginTop: '1.5rem', opacity: 0.1 }} />
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="addressLine1" section="myAccount" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="addressLine2" section="myAccount" />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="city" section="myAccount" />
        </Grid>
        <Grid item xs={12} md={3}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="state" section="myAccount" />
        </Grid>
        <Grid item xs={12} md={3}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="zip" section="myAccount" />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="phone" section="myAccount" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="phoneAlternative" section="myAccount" />
        </Grid>
      </Grid>
      <hr style={{ marginTop: '1.5rem', opacity: 0.1 }} />
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="sponsorLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="facebookLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="twitterLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="youtubeLink" section="myAccount" />
          <AdminTextInput
            disabled={inputsDisabled || !isSystemOwner(authState)}
            t={t}
            required={!(inputsDisabled || !isSystemOwner(authState))}
            name="readMoreLink"
            section="myAccount"
            identifier="read-more-link"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminTextInput disabled={inputsDisabled} t={t} name="instagramLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="linkedinLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="websiteLink" section="myAccount" />
          <AdminTextInput disabled={inputsDisabled} t={t} name="donateOnlineLink" section="myAccount" />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginTop: '1rem' }}
          color="secondary"
          variant="contained"
          onClick={props.submitForm}
          size="medium"
          type="submit"
          disabled={props.isSubmitting || !props.isValid || !props.dirty}
        >
          {t('common.save')}
        </Button>
      </Box>
    </Form>
  );

  return (
    <>
      {loading && <Loader />}
      {!loading && organizationData && (
        <Formik
          initialValues={{
            sponsorLink: organizationData.sponsorLink,
            name: organizationData.name,
            contactPerson: organizationData.contactPerson,
            contactEmail: organizationData.contactEmail || '',
            facebookLink: organizationData.facebookLink,
            linkedinLink: organizationData.linkedinLink,
            instagramLink: organizationData.instagramLink,
            twitterLink: organizationData.twitterLink,
            youtubeLink: organizationData.youtubeLink,
            websiteLink: organizationData.websiteLink,
            addressLine1: organizationData.addressLine1,
            addressLine2: organizationData.addressLine2,
            city: organizationData.city,
            state: organizationData.state,
            zip: organizationData.zip,
            phone: organizationData.phone,
            readMoreLink: organizationData.readMoreLink || '',
            donateOnlineLink: organizationData.donateOnlineLink,
            phoneAlternative: organizationData.phoneAlternative,
            bulletin: organizationData.bulletin,
            email: organizationData.email,
            flyer: organizationData.flyer,
            newsletter: organizationData.newsletter,
            directory: organizationData.directory
          }}
          validationSchema={Yup.object().shape({
            sponsorLink: Yup.string().url().nullable().max(app.maxInputLength),
            name: Yup.string().nullable().max(app.maxInputLength),
            contactPerson: Yup.string().nullable().max(app.maxInputLength),
            contactEmail: Yup.string().max(app.maxInputLength).email(),
            facebookLink: Yup.string().url().nullable().max(app.maxInputLength),
            linkedinLink: Yup.string().url().nullable().max(app.maxInputLength),
            instagramLink: Yup.string().url().nullable().max(app.maxInputLength),
            twitterLink: Yup.string().url().nullable().max(app.maxInputLength),
            youtubeLink: Yup.string().url().nullable().max(app.maxInputLength),
            websiteLink: Yup.string().url().nullable().max(app.maxInputLength),
            readMoreLink: Yup.string().max(app.maxInputLength),
            donateOnlineLink: Yup.string().url().nullable().max(app.maxInputLength),
            addressLine1: Yup.string().nullable().max(app.maxInputLength),
            addressLine2: Yup.string().nullable().max(app.maxInputLength),
            city: Yup.string().nullable().max(app.maxInputLength),
            state: Yup.string().nullable().max(app.maxInputLength),
            zip: Yup.string().nullable().max(app.maxInputLength),
            phone: Yup.string().nullable().max(app.maxInputLength),
            phoneAlternative: Yup.string().nullable().max(app.maxInputLength),
            bulletin: Yup.boolean().nullable(),
            email: Yup.boolean().nullable(),
            flyer: Yup.boolean().nullable(),
            newsletter: Yup.boolean().nullable(),
            directory: Yup.boolean().nullable()
          })}
          onSubmit={saveOrganizationData}
        >
          {renderOrganizationDataForm}
        </Formik>
      )}
    </>
  );
};

export default OrganizationLogoForm;
