import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { ChangePasswordFormValues } from '../../../../store/Auth/types';
import { createNetworkErrorObject } from '../../../../utils';
import Toast from '../../../Shared/Toast/Toast';
import { authOperations } from '../../../../store/Auth';
import { PasswordPolicy } from '../../../../store/SystemSettings/types';
import Loader from '../../../Shared/Loading/Loader';
import { useDispatch } from 'react-redux';
import SetPasswordForm from '../../../Pages/Auth/ValidateAndSetPassword/SetPasswordForm';
import { Row } from '../../../Shared/StyledComponents';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';

type PasswordExpiredWindowProps = {
  open: boolean;
  passwordPolicy: PasswordPolicy | null;
};

const PasswordExpiredWindow: FunctionComponent<PasswordExpiredWindowProps> = ({ open, passwordPolicy }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formSubmitError, setFormSubmitError] = useState<NetworkError | null>(null);

  const onSubmit = async (values: ChangePasswordFormValues) => {
    setFormSubmitError(null);
    try {
      await authOperations.changePassword(values);
      await dispatch(authOperations.validateToken());

      Toast.success(t('notifications.myAccount.successOperation'));
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      setFormSubmitError(networkError);
    }
  };

  return (
    <ModalConfirmationWindow
      onCloseClick={() => null}
      open={open}
      header={t('windows.yourPasswordExpired.title')}
      hideActionButtons
      disableBackdropClick={true}
      disableCloseButton={true}
    >
      {!passwordPolicy && <Loader />}
      {passwordPolicy && (
        <>
          <Row>
            {t('windows.yourPasswordExpired.content')}
            <Button
              color={'secondary'}
              variant="text"
              size="small"
              onClick={() => dispatch(push(linksConstants.AUTH.LOGOUT))}
            >
              {t('navigation.signOut')}
            </Button>
          </Row>
          <SetPasswordForm
            handleSubmit={onSubmit}
            formSubmitError={formSubmitError}
            withPreviousPassword
            passwordPolicy={passwordPolicy}
          />
        </>
      )}
    </ModalConfirmationWindow>
  );
};

export default PasswordExpiredWindow;
