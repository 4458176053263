export default {
  MY_ACCOUNT: '/my_account',
  FLIPBOOK_PUBLIC: '/flipbook',
  CALENDAR_PUBLIC: '/org_calendar',
  CALENDAR: '/calendar',
  CAMPAIGNS: {
    INDEX: '/campaigns',
    EDIT: (campaignId: string) => `/campaigns/${campaignId}`,
    ADD_PROJECTS: (campaignId: string) => `/campaigns/${campaignId}/projects`,
    ASSIGN: (projectId: string, projectType: string) => `/campaigns/${projectId}/${projectType}/assign`
  },
  PUBLIC_OPEN_AI_POLICY: '/ai_policy',
  PROJECT_DETAILS: (projectId: string) => `/project_details/${projectId}`,
  MY_ORGANIZATION: '/my_organization',
  ADS_STATISTICS: '/ads_statistics',
  ADMIN_PROJECTS: '/projects_administration',
  SUPPORT: '/support',
  PLAYGROUND: {
    FLIP_BOOK: (projectId: string) => `/flip_book/${projectId}`
  },
  ADMIN_REPORTS: {
    INDEX: '/system_reports'
  },
  PROJECTS: {
    EMAIL: {
      EDIT: (projectId: string) => `/newsletter/${projectId}/editor`,
      EDIT_OLD: (projectId: string) => `/newsletter/${projectId}/editor_old`,
      STATISTICS: (projectId: string) => `/statistics/${projectId}`,
      EMAIL_DND_EDITOR: '/email_editor',
      PREVIEW: (previewUrl: string, contentWidth: string) => `/newsletter/preview/${previewUrl}/${contentWidth}`
    },
    BULLETIN: {
      EDIT: (projectId: string) => `/bulletin/${projectId}/editor`
    },
    DESIGN: {
      EDIT: (projectId: string) => `/design/${projectId}/editor`
    }
  },
  MAILING_LISTS: {
    INDEX: '/mailing_lists',
    EDIT: (mailingListId: string, mailingListType: string) =>
      `/mailing_lists/${mailingListId}/subscribers/${mailingListType}`
  },
  AUTH: {
    LOGIN: '/login',
    SIGN_UP: '/sign_up',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot_password',
    RESEND_VERIFICATION: '/resend_verification',
    VERIFY: '/verify/:ticketId',
    RESET_PASSWORD: '/reset/:ticketId'
  },
  STORY: {
    INDEX: '/story',
    CONTENT_CONTRIBUTORS: (id: string) => `/story/${id}/contributors`,
    EDIT: (id: string) => `/story/${id}/edit`,
    DESIGNS: (storyId: string, channelId: string) => `/story/${storyId}/designs/${channelId}`
  },
  DASHBOARD: {
    INDEX: '/dashboard',
    PRINT_PROJECTS: '/print_projects',
    DESIGN_PROJECTS: '/design_projects',
    EMAIL_PROJECTS: '/email_projects',
    NEW_PROJECT: '/new',
    SHARING_CENTER: '/sharing_center',
    CLONE_PROJECT: (id: string) => `/clone/${id}`,
    PROJECT_CREATOR: {
      email: '/new/email',
      bulletin: '/new/bulletin',
      flyer: '/new/flyer',
      newsletter: '/new/newsletter',
      directory: '/new/directory',
      design: '/new/design'
    }
  },
  ADMINISTRATION: {
    MAILING_LISTS: {
      INDEX: '/mailing_lists_dashboard',
      EDIT: (mailingListId: string, mailingListType: string) =>
        `/mailing_lists_dashboard/${mailingListId}/subscribers/${mailingListType}`
    },
    USERS: {
      INDEX: '/users'
    },
    ONLINE_USERS: {
      INDEX: '/online_users'
    },
    ADS: {
      INDEX: '/house_ads',
      ASSIGN_ADS: (templateId: string) => `/house_ads/${templateId}/assign_ads`
    },
    ROLES: {
      INDEX: '/roles_administration',
      CREATE: '/roles_administration/create',
      EDIT: (projectId: string) => `/roles_administration/${projectId}/edit`
    },
    PRINT_TEMPLATES: {
      INDEX: '/print_templates_administration'
    },
    EMAIL_TEMPLATES: {
      INDEX: '/email_templates_administration',
      EDIT: (templateId: string) => `/email_templates_editor/${templateId}/edit`
    },
    KEYWORDS: {
      INDEX: '/keywords_administration'
    },
    KEYWORD_GROUPS: {
      INDEX: '/keyword_groups_administration'
    },
    SYSTEM_SETTINGS: {
      INDEX: '/system_settings'
    },
    PASSWORD_POLICIES: {
      INDEX: '/password_policies_administration'
    },
    ORGANIZATIONS: {
      INDEX: '/organizations_administration',
      CREATE: '/organizations_administration/create',
      EDIT: (organizationId: string) => `/organizations_administration/${organizationId}/edit`,
      USERS: (organizationId: string) => `/organizations_administration/${organizationId}/users`,
      ADS: (organizationId: string) => `/organizations_administration/${organizationId}/ads`,
      ADS_ASSIGNMENT: (organizationId: string) => `/organizations_administration/${organizationId}/ads_assignment`
    }
  },
  SERVICES: {
    SUBSCRIBERS: '/subscribers'
  },
  COMMON: {
    UNSUBSCRIBED: '/unsubscribed'
  }
};
