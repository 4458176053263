import moment from 'moment';
import app from '../config/app/app';
import { parseInt } from 'lodash';

export function formatPublishDate(publishDate: Date | string) {
  return moment.utc(publishDate).format(app.dateFormats.projectPublishDate);
}

export function formatTableTimestamp(date: Date | string) {
  return moment(date).format(app.dateFormats.projectPublishDate);
}

export function formatLpiExpressDate(date: Date | string) {
  return moment(date).format(app.dateFormats.lpiExpressDate);
}

export function formatNoteDate(date: Date | string) {
  return moment(date).format(app.dateFormats.noteDateAndTime);
}

export function getUtcDate(inputDate: Date | string): Date {
  const date = new Date(inputDate);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function addNegativeTimezoneOffset(inputDate: Date | string): Date {
  const date = new Date(inputDate);
  const offset = date.getTimezoneOffset();
  if (offset > 0) {
    return new Date(date.getTime() + offset * 60000);
  }

  return date;
}

export function insertTrailingZero(num: number): string {
  if (num >= 0 && num < 10) {
    return `0${num}`;
  }

  return num.toString();
}
export function timestampToTime(timestamp: string | null) {
  if (!timestamp) return timestamp;

  const time = new Date(parseInt(timestamp));
  const hour = insertTrailingZero(time.getHours());
  const minute = insertTrailingZero(time.getMinutes());
  return `${hour}:${minute}`;
}

export function timeToTimestamp(value: string | null) {
  if (!value) return null;

  const date = new Date();
  const [hours, minutes] = value.split(':');
  date.setHours(parseInt(hours || '0'));
  date.setMinutes(parseInt(minutes || '0'));
  date.setSeconds(0);
  return date.getTime().toString();
}
