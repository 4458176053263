import React, { CSSProperties, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ImageData, ImageDropObjectData, ItemTypes } from '../../../types';
import styled from 'styled-components';
import InnerImageElement from './InnerImageElement';
import Toast from '../../../../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../../Shared/Loading/Loader';
import { ImageProcessingResponse } from '../../../../../../../store/Images/types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ReactComponent as SettingsIcon } from '../../../../../../../assets/icons/settings.svg';
import { ImageActionIcons, StyledEditIcon } from '../../../../../../Shared/StyledComponents';
import useOpenHandler from '../../../../../../../hooks/useOpenHandler';
import EditImageLinkWindow from '../../Windows/EditImageLinkWindow';
import WysiwygEditor from './WysiwygEditor';

type ImageElementProps = {
  data: ImageData;
  onChange: (data: ImageData) => void;
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>;
  styles?: CSSProperties;
  applyingStory?: boolean;
  placeholderText: string;
  type?: 'article-horizontal' | 'article-vertical';
};

const ImageElement: FunctionComponent<ImageElementProps> = ({
  data,
  onChange,
  onImageDrop,
  styles,
  applyingStory,
  placeholderText,
  type
}) => {
  const { t } = useTranslation();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.BRANDFOLDER, ItemTypes.ORGANIZATION_UPLOADED_ASSET, ItemTypes.DESIGN_ASSET, NativeTypes.FILE],
    // @ts-ignore
    drop: (e: ImageDropObjectData) => onDrop(e),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const [editImageLinkWindowOpen, onEditImageLinkWindowOpen, onEditImageLinkWindowClose] = useOpenHandler();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [applyingImage, setApplyingImage] = useState<boolean>(false);

  const [innerAdjustmentEnabled, setInnerAdjustmentEnabled] = useState<boolean>(false);
  const resizingPrevState = useRef<boolean>(false);
  const [resizing, setResizing] = useState<boolean>(false);
  const containerResizeStartCoords = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const parentColumnMaxWidth = useRef<number>(parseInt(String(data.containerWidth || data.width)));
  const [containerWidth, setContainerWidth] = useState<number>(parseInt(String(data.containerWidth || data.width)));
  const [containerHeight, setContainerHeight] = useState<number>(parseInt(String(data.containerHeight || data.height)));

  const startResize = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    containerResizeStartCoords.current = {
      x: e.clientX,
      y: e.clientY
    };
    if (wrapperRef.current) {
      const closestColumn: HTMLDivElement | null = wrapperRef.current.closest('.email-column');
      if (closestColumn) {
        const computedStyles = getComputedStyle(closestColumn);
        parentColumnMaxWidth.current =
          closestColumn.offsetWidth - parseFloat(computedStyles.paddingLeft) - parseFloat(computedStyles.paddingRight);
      }
      if (type === 'article-horizontal') {
        // allow the image to take max 50% width of article-horizontal component
        parentColumnMaxWidth.current = parentColumnMaxWidth.current - parentColumnMaxWidth.current / 2;
      }
    }
    setResizing(true);
    document.addEventListener('mouseup', stopResize);
    document.addEventListener('mousemove', mouseMove);
  };

  useEffect(() => {
    if (resizingPrevState.current && !resizing) {
      onChange({
        ...data,
        containerWidth,
        containerHeight
      });
    }
    resizingPrevState.current = resizing;
  }, [resizing]);

  const stopResize = () => {
    setResizing(false);
    document.removeEventListener('mouseup', stopResize);
    document.removeEventListener('mousemove', mouseMove);
  };

  const mouseMove = useCallback(
    (e: MouseEvent) => {
      let targetWidth =
        containerWidth + (e.clientX - containerResizeStartCoords.current.x) * (type === 'article-horizontal' ? 1 : 2);
      if (targetWidth >= parentColumnMaxWidth.current) {
        targetWidth = parentColumnMaxWidth.current;
      }
      const targetHeight = containerHeight + e.clientY - containerResizeStartCoords.current.y;
      setContainerWidth(targetWidth <= 80 ? 80 : targetWidth);
      setContainerHeight(targetHeight <= 40 ? 40 : targetHeight);
    },
    [containerWidth, containerHeight]
  );

  const onDrop = async (event: ImageDropObjectData) => {
    setApplyingImage(true);
    try {
      const uploadResponse = await onImageDrop(event, data);
      if (uploadResponse) {
        const updatedData = {
          ...data,
          url: uploadResponse.url,
          width: uploadResponse.size[0],
          height: uploadResponse.size[1],
          positionX: 0,
          positionY: 0
        };
        if (event && event.type === 'design-asset' && event.id) {
          updatedData.printDesignId = event.id;
        } else {
          updatedData.printDesignId = null;
        }

        onChange(updatedData);
      }
    } catch (e) {
      /** Error on file upload */
      Toast.error(t('notifications.uploadEmailFile.unknownError'));
    } finally {
      setApplyingImage(false);
    }
  };

  const isDraggingOver = canDrop && isOver;

  const imgElement =
    applyingImage || applyingStory ? null : (
      <InnerImageElement
        data={data}
        onChange={onChange}
        innerAdjustmentEnabled={innerAdjustmentEnabled}
        setInnerAdjustmentEnabled={setInnerAdjustmentEnabled}
        placeholderText={placeholderText}
      />
    );

  return (
    <>
      <MainWrapper
        resizing={resizing}
        innerAdjustmentEnabled={innerAdjustmentEnabled}
        style={{
          width: containerWidth,
          height: containerHeight
        }}
      >
        <ResizableImageWrapper
          ref={(node) => {
            wrapperRef.current = node;
            drop(node);
          }}
          onDoubleClick={() => setInnerAdjustmentEnabled(true)}
          resizing={resizing}
          innerAdjustmentEnabled={innerAdjustmentEnabled}
          style={{
            ...styles,
            width: containerWidth,
            height: containerHeight,
            borderTopLeftRadius: data.borderTopLeftRadius ? data.borderTopLeftRadius + 'px' : 0,
            borderTopRightRadius: data.borderTopRightRadius ? data.borderTopRightRadius + 'px' : 0,
            borderBottomLeftRadius: data.borderBottomLeftRadius ? data.borderBottomLeftRadius + 'px' : 0,
            borderBottomRightRadius: data.borderBottomRightRadius ? data.borderBottomRightRadius + 'px' : 0
          }}
          className={isDraggingOver ? 'image-drag-over' : ''}
        >
          {imgElement}
          <ResizeHandle onMouseDown={startResize} />
          {innerAdjustmentEnabled && <Backdrop onClick={() => setInnerAdjustmentEnabled(false)} />}
          {(applyingImage || applyingStory) && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </ResizableImageWrapper>

        <ImageActionIcons>
          <StyledEditIcon onClick={() => onEditImageLinkWindowOpen()}>
            <SettingsIcon style={{ width: 22, fill: '#fff' }} />
          </StyledEditIcon>
        </ImageActionIcons>

        {editImageLinkWindowOpen && (
          <EditImageLinkWindow
            data={data}
            open={editImageLinkWindowOpen}
            onCloseClick={onEditImageLinkWindowClose}
            onFormSubmit={(
              link,
              hasCaption,
              borderTopLeftRadius,
              borderTopRightRadius,
              borderBottomLeftRadius,
              borderBottomRightRadius
            ) => {
              onChange({
                ...data,
                linkUrl: link,
                hasCaption,
                borderTopLeftRadius,
                borderTopRightRadius,
                borderBottomLeftRadius,
                borderBottomRightRadius,
                caption: data.caption
                  ? data.caption
                  : t('pages.emailEditor.editImageLinkWindow.inputs.captionPlaceholder')
              });
              onEditImageLinkWindowClose();
            }}
            fullScreenOnMobile={true}
          />
        )}
      </MainWrapper>
      {data.hasCaption && (
        <div style={{ fontSize: '10pt' }}>
          <WysiwygEditor
            inline
            content={data.caption}
            onBlur={(newContent) =>
              onChange({
                ...data,
                caption: newContent
              })
            }
          />
        </div>
      )}
    </>
  );
};

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
`;

const ResizeHandle = styled.div`
  display: none;
  width: 0;
  height: 0;
  border-bottom: 40px solid rgba(243, 156, 18, 0.5);
  border-left: 40px solid transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
  z-index: 19;
`;

const ResizableImageWrapper = styled.div<{ resizing: boolean; innerAdjustmentEnabled: boolean }>`
  position: relative;
  overflow: ${({ innerAdjustmentEnabled }) => (innerAdjustmentEnabled ? 'visible' : 'hidden')};
  margin: 0 auto;

  ${({ resizing }) =>
    resizing &&
    `
    outline: 4px solid rgba(243, 156, 18, 0.35);
  `}

  &:hover {
    outline: 4px solid rgba(243, 156, 18, 0.35);
  }

  &:hover ${ResizeHandle} {
    display: block;
  }

  &.image-drag-over:after {
    content: 'Drop here...';
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    outline: 4px solid #f39c12;
    font-size: 1rem;
  }
`;

const MainWrapper = styled.div<{ resizing: boolean; innerAdjustmentEnabled: boolean }>`
  position: relative;
  margin: 0 auto;

  &:hover ${StyledEditIcon} {
    display: flex;
  }
`;

export default ImageElement;
